import { createTheme } from "@mui/material/styles"
import { colors, dimensions, typography } from "../library/designTokens"

const theme = createTheme({
  palette: {
    primary: { main: colors.blue, light: colors.blueLight, dark: colors.blueDarker },
    secondary: { main: colors.turquoise, light: colors.turquoiseLight, dark: colors.turquoiseDark },
    error: { main: colors.red },
    success: { main: colors.pink },
    info: { main: colors.turquoise },
    warning: { main: colors.orange },
    background: {
      default: colors.white,
      paper: colors.white,
    },
    text: {
      primary: colors.black,
      secondary: colors.gray,
      neutral: colors.neutral100,
      neutral120: colors.neutral120,
      neutral140: colors.neutral140,
      neutral160: colors.neutral160,
      neutral180: colors.neutral180,
    },
  },
  typography: {
    fontFamily: typography.fontFamilyBase,
    h1: { fontSize: typography.sizes.h1, fontWeight: typography.fontWeightSemiBold },
    h2: { fontSize: typography.sizes.h2, fontWeight: typography.fontWeightSemiBold },
    h3: { fontSize: typography.sizes.h3, fontWeight: typography.fontWeightSemiBold },
    h4: { fontSize: typography.sizes.h4, fontWeight: typography.fontWeightSemiBold },
    h5: { fontSize: typography.sizes.h5, fontWeight: typography.fontWeightSemiBold },
    h6: { fontSize: typography.sizes.h6, fontWeight: typography.fontWeightSemiBold },
    body1: { fontSize: typography.sizes.body1, fontWeight: typography.fontWeightRegular },
  },
  shape: {
    borderRadius: dimensions.borderRadiusSmall,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: dimensions.buttonMinWidth,
          borderRadius: dimensions.borderRadiusMedium,
          fontWeight: typography.fontWeightBold,
          padding: `${dimensions.buttonPaddingY} ${dimensions.buttonPaddingX}`,
          fontFamily: typography.fontFamilyBtn,
          fontSize: typography.sizes.btn,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.blue,
          padding: `${dimensions.tooltipPaddingY} ${dimensions.tooltipPaddingX}`,
          maxWidth: dimensions.tooltipMaxWidth,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: dimensions.borderRadiusSmall,
          padding: dimensions.alertPadding,
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          borderRadius: dimensions.borderRadiusLarge,
          borderColor: "transparent",
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
          borderColor: "transparent",
        },
      },
    },
  },
})

export default theme
