import { Card, CardContent, CardHeader, Stack, Typography } from "@mui/material"
import { styled } from "@mui/system"
import PropTypes from "prop-types"
import React from "react"
import ProgramDetails from "../ProgramDetails"
import { StyledButton, StyledCard } from "../StyledComponents"

const StudentListStyledCard = styled(Card)({
  borderRadius: 8,
  backgroundColor: "#f3f3f9",
  borderColor: "#e6e6e6",
  padding: "0.6rem",
})

const StudentList = ({ students }) => (
  <Stack spacing={1}>
    {students.map(({ student_name }) => (
      <StudentListStyledCard key={student_name} variant="outlined">
        <Typography variant="body1" color="text.neutral140" sx={{ fontWeight: "600" }}>
          {student_name}
        </Typography>
      </StudentListStyledCard>
    ))}
  </Stack>
)

StudentList.propTypes = {
  students: PropTypes.arrayOf(
    PropTypes.shape({
      student_name: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export const StudentAdministeredTutorView = ({ data }) => (
  <StyledCard>
    <CardHeader title={<ProgramDetails programName={data.program_name} assignmentName={data.assignment_name} />} />
    <CardContent>
      {data.assignments?.student_administered?.length > 0 ? (
        <>
          <Typography variant="body1" color="text.neutral120" sx={{ fontWeight: "400", mb: 1 }}>
            Complete assessment for:
          </Typography>
          <StudentList students={data.assignments.student_administered} />
        </>
      ) : (
        <Typography variant="body1" color="text.secondary" sx={{ fontWeight: "600" }}>
          No assessments available at this time.
        </Typography>
      )}
    </CardContent>
  </StyledCard>
)

StudentAdministeredTutorView.propTypes = {
  data: PropTypes.shape({
    program_name: PropTypes.string.isRequired,
    assignment_name: PropTypes.string,
    assignments: PropTypes.shape({
      student_administered: PropTypes.arrayOf(
        PropTypes.shape({
          student_name: PropTypes.string.isRequired,
        })
      ),
    }),
  }).isRequired,
}

export const StudentAdministeredStudentView = ({ data }) => (
  <StyledCard>
    <CardHeader title={<ProgramDetails programName={data.program_name} assignmentName={data.assignment_name} />} />
    <CardContent>
      {data.assignment_url ? (
        <StyledButton
          onClick={() => window.open(data.assignment_url, "_blank", "noopener,noreferrer")}
          variant="contained"
          aria-label="Take Assessment"
          disableElevation
        >
          Take Assessment
        </StyledButton>
      ) : (
        <Typography variant="body1" color="text.secondary" sx={{ fontWeight: "600" }}>
          No assessment available at this time.
        </Typography>
      )}
    </CardContent>
  </StyledCard>
)

StudentAdministeredStudentView.propTypes = {
  data: PropTypes.shape({
    program_name: PropTypes.string.isRequired,
    assignment_name: PropTypes.string,
    assignment_url: PropTypes.string,
  }).isRequired,
}
