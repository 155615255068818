import PropTypes from "prop-types"
import React, { useMemo } from "react"
import { LessonStudentView, LessonTutorView } from "./Lesson"
import { LoadingSkeleton } from "./LoadingSkeleton"
import NoResources from "./NoResources"
import { StudentAdministeredStudentView, StudentAdministeredTutorView } from "./StudentAdministered"
import { TutorAdministeredStudentView, TutorAdministeredTutorView } from "./TutorAdministered"

const COMPONENTS = {
  student_administered: {
    admin: StudentAdministeredTutorView,
    tutor: StudentAdministeredTutorView,
    student: StudentAdministeredStudentView,
  },
  tutor_administered: {
    admin: TutorAdministeredTutorView,
    tutor: TutorAdministeredTutorView,
    student: TutorAdministeredStudentView,
  },
}

const getComponent = ({ resourceType, userRole }) => {
  const Component = COMPONENTS[resourceType]?.[userRole]
  if (!Component) {
    // eslint-disable-next-line no-console
    console.error(`Invalid resourceType or userRole: ${resourceType}, ${userRole}`)
    return NoResources
  }
  return Component
}

export const ResourcesComponent = React.memo(({ loading, data, userRole }) => {
  const content = useMemo(() => {
    if (loading || !data) {
      return <LoadingSkeleton />
    }

    if (!data.assignment_name && !data.lesson_resources) {
      return <NoResources data={data} />
    }

    if (data.assignment_type === "Lesson" || data.lesson_resources) {
      return userRole === "student" ? <LessonStudentView data={data} /> : <LessonTutorView data={data} />
    }

    const resourceType = data.is_tutor_administered ? "tutor_administered" : "student_administered"
    const Component = getComponent({ resourceType, userRole })
    return <Component data={data} />
  }, [loading, data, userRole])

  return <div className="max-w-md mx-auto">{content}</div>
})

ResourcesComponent.displayName = "ResourcesComponent"

ResourcesComponent.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    assignment_name: PropTypes.string,
    assignment_url: PropTypes.string,
    assignment_type: PropTypes.string,
    lesson_resources: PropTypes.any,
    is_tutor_administered: PropTypes.bool,
  }),
  userRole: PropTypes.oneOf(["admin", "tutor", "student"]).isRequired,
}

export default ResourcesComponent
