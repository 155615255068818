import ClassroomContainer from "@/components/classroom"
import BRTThemeProvider from "@/providers/theme"
import { Box } from "@mui/material"
import React from "react"
import useGetLessonspaceClassroom from "../hooks/useGetLessonspaceClassroom"

// LessonspaceClassroomPage Component
export const LessonspaceClassroomPage = ({ current_user }) => {
  const scheduleId = window.location.pathname.split("/").pop()
  const { data, loading, error } = useGetLessonspaceClassroom({ scheduleId })
  const userRole = current_user?.role
  const isCurriculumProgram = data?.is_curriculum_program || false

  return (
    <BRTThemeProvider>
      <Box>
        <ClassroomContainer
          loading={loading}
          error={error}
          classroomLink={data?.classroom_link}
          data={data}
          userRole={userRole}
          isCurriculumProgram={isCurriculumProgram}
        />
      </Box>
    </BRTThemeProvider>
  )
}

export default LessonspaceClassroomPage
