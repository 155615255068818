import React, { memo, useContext, useMemo } from "react"
import { Card, Table } from "react-bootstrap"
import {
  Box,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import TableSkeletonLoader from "../../../../common/skeleton_loaders/TableSkeletonLoader"
import { DataContext, QueryContext } from "../../../../contexts"
import NoResultsSection from "../../../admin/users/school_admins/NoResults"
import { getSchedule } from "../../configurations/domain"

const columns = [
  { field: "name", headerName: "Student", flex: 1, headerClassName: "table-header", resizable: false },
  {
    field: "assessment_score_baba",
    headerName: "Advanced Reading Pre-Assessment Score",
    flex: 1,
    headerClassName: "table-header",
    resizable: false,
  },
  {
    field: "assessment_score_BSA",
    headerName: "Beginning Spelling Assessment Score",
    flex: 1,
    headerClassName: "table-header",
    resizable: false,
  },
  {
    field: "assessment_score_BRA",
    headerName: "Beginning Reading Assessment Score",
    flex: 1,
    headerClassName: "table-header",
    resizable: false,
  },
  {
    field: "suggested_level",
    headerName: "Suggested Level",
    flex: 1,
    headerClassName: "table-header",
    resizable: false,
  },
  {
    field: "suggested_lessons",
    headerName: "Suggested Lessons",
    flex: 1,
    headerClassName: "table-header",
    resizable: false,
  },
]

const LessonRecommendation = () => {
  const { schedule } = useContext(DataContext)
  const { getHookState } = useContext(QueryContext)
  const { loading: loadingSchedule } = getHookState({
    ...getSchedule,
    skipStorage: true,
  })

  const { group_suggested_lesson, group_suggested_level, student_recommendations } =
    schedule?.lesson_recommendations || {
      group_suggested_lesson: "",
      group_suggested_level: "",
      student_recommendations: [],
    }
  const rows = useMemo(() => student_recommendations, [student_recommendations])
  const hasLessonRecommendations = rows.length && columns.length

  return (
    <Card className="mt-4 p-4">
      <h6>
        Lesson Recommendation
        <Box
          display="inline-flex"
          alignItems="center"
          className="ml-3"
          sx={{
            backgroundColor: "#c7edf5",
            borderRadius: "6px",
            padding: "4px 8px",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: "small",
              fontWeight: "600",
            }}
          >
            beta
          </Typography>
          <InfoOutlinedIcon
            sx={{
              marginLeft: "4px",
              fontSize: "18px",
              color: "black",
              "&:hover": {
                color: "black !important",
                cursor: "default",
              },
            }}
          />
        </Box>
      </h6>
      {loadingSchedule ? (
        <TableSkeletonLoader />
      ) : (
        <TableContainer className="mt-2">
          {!hasLessonRecommendations ? (
            <NoResultsSection />
          ) : (
            <Paper
              style={{
                width: "100%",
                overflowX: "auto",
              }}
            >
              <Table style={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {columns.map(c => (
                      <TableCell
                        className="text-gray-chat font-weight-medium font-size-small"
                        sx={{
                          "&.MuiTableCell-head": {
                            borderTop: "none",
                          },
                        }}
                        key={c}
                        align="left"
                        component="th"
                      >
                        {c.headerName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map(row => (
                    <TableRow key={row.name || "-"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell>{row.name || "-"}</TableCell>
                      <TableCell>{row.assessment_score_BABA || "-"}</TableCell>
                      <TableCell>{row.assessment_score_BSA || "-"}</TableCell>
                      <TableCell>{row.assessment_score_BRA || "-"}</TableCell>
                      <TableCell>{row.suggested_lessons.join(", ") || "-"}</TableCell>
                      <TableCell>{group_suggested_level}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableCell
                    key={"group_recommendation"}
                    sx={{
                      fontWeight: "500",
                      fontSize: "13px",
                      color: "#030303",
                      borderBottom: "none",
                    }}
                    className="font-size-small font-weight-medium"
                  >
                    Group Recommendation
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "500",
                      fontSize: "13px",
                      color: "#030303",
                      borderBottom: "none",
                    }}
                  />
                  <TableCell
                    sx={{
                      fontWeight: "500",
                      fontSize: "13px",
                      color: "#030303",
                      borderBottom: "none",
                    }}
                  />
                  <TableCell
                    sx={{
                      fontWeight: "500",
                      fontSize: "13px",
                      color: "#030303",
                      borderBottom: "none",
                    }}
                  />
                  <TableCell
                    key={group_suggested_lesson}
                    sx={{
                      fontWeight: "500",
                      fontSize: "13px",
                      color: "#030303",
                      borderBottom: "none",
                    }}
                    className="font-size-small font-weight-medium"
                  >
                    {group_suggested_lesson || "-"}
                  </TableCell>
                  <TableCell
                    key={group_suggested_level}
                    sx={{
                      fontWeight: "500",
                      fontSize: "13px",
                      color: "#030303",
                      borderBottom: "none",
                    }}
                    className="font-size-small font-weight-medium"
                  >
                    {group_suggested_level || "-"}
                  </TableCell>
                </TableFooter>
              </Table>
            </Paper>
          )}
        </TableContainer>
      )}
    </Card>
  )
}

export default memo(LessonRecommendation)
