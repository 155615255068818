import { Alert, Box, Card, Container, LinearProgress, Typography } from "@mui/material"
import { keyframes, styled } from "@mui/material/styles"
import Grid2 from "@mui/material/Unstable_Grid2"
import PropTypes from "prop-types"
import React from "react"
import { NoSession } from "../students/dashboard/hero_section/NoSession"
import ResourcesComponent from "./Resources"

// Keyframe for loading animation
const pulsate = keyframes`
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.8;
  }
`

// Styled Component for the iframe
const IframeContainer = styled("iframe")(({ theme }) => ({
  height: "80vh",
  width: "100%",
  border: "none",
  borderRadius: theme.shape.borderRadius,
}))

// Styled Card for loading state
const StyledLoadingCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  height: "80vh",
  backgroundColor: "#e6e4f1",
}))

// ClassroomIframe Component
const ClassroomIframe = React.memo(({ classroomLink }) => (
  <IframeContainer
    src={classroomLink}
    title="LessonSpace Session"
    allow="camera; microphone; display-capture; autoplay"
    allowFullScreen
    aria-label="LessonSpace Classroom Session"
  />
))
ClassroomIframe.displayName = "ClassroomIframe"

// LessonSpaceContent Component
const LessonSpaceContent = React.memo(({ loading, classroomLink }) => {
  if (loading) {
    return (
      <StyledLoadingCard aria-busy="true" aria-live="polite">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              mb: 1,
              textAlign: "center",
              animation: `${pulsate} 2s infinite`,
              transition: "transform 0.2s ease-in-out, opacity 0.2s ease-in-out",
            }}
            aria-label="Loading LessonSpace Classroom"
          >
            Loading Classroom
          </Typography>
          <LinearProgress sx={{ width: "100%" }} />
        </Box>
      </StyledLoadingCard>
    )
  }

  if (!classroomLink) {
    return (
      <StyledLoadingCard>
        <NoSession message="There is no LessonSpace session found" />
      </StyledLoadingCard>
    )
  }

  return <ClassroomIframe classroomLink={classroomLink} />
})

LessonSpaceContent.displayName = "LessonSpaceContent"

// ClassroomContainer Component
const ClassroomContainer = React.memo(({ loading, error, classroomLink, data, userRole, isCurriculumProgram }) => {
  return (
    <Container maxWidth="xl" sx={{ flexGrow: 1 }} disableGutters>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }} role="alert">
          {error}
        </Alert>
      )}
      <Grid2 container spacing={3} justifyContent={!classroomLink ? "center" : undefined}>
        <Grid2 item xs={12} md={isCurriculumProgram ? 8 : 12}>
          <LessonSpaceContent loading={loading} classroomLink={classroomLink} />
        </Grid2>
        {isCurriculumProgram && (
          <Grid2 item xs={12} md={4}>
            <ResourcesComponent loading={loading} data={data} userRole={userRole} />
          </Grid2>
        )}
      </Grid2>
    </Container>
  )
})

ClassroomContainer.displayName = "ClassroomContainer"

ClassroomContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  classroomLink: PropTypes.string,
  data: PropTypes.object,
  userRole: PropTypes.oneOf(["admin", "tutor", "student"]).isRequired,
  isCurriculumProgram: PropTypes.bool,
}

ClassroomContainer.defaultProps = {
  error: null,
  classroomLink: null,
  data: null,
  isCurriculumProgram: false,
}

export default React.memo(ClassroomContainer)
