import { Button, Card, styled } from "@mui/material"

export const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "30px",
  marginTop: theme.spacing(2),
  width: "100%",
  fontSize: "1rem",
  transition: "background-color 0.3s ease-in-out",

  "&:focus": {
    outline: `2px solid ${theme.palette.primary.main}`,
    outlineOffset: "2px",
  },
  "&:hover": {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.dark,
  },
}))

export const StyledCard = styled(Card)(({ theme }) => ({
  minHeight: "80vh",
  padding: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  borderTop: `4px solid ${theme.palette.primary.main}`,
}))
