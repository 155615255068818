import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Skeleton } from '@mui/material';
import PropTypes from "prop-types"


const TableSkeletonLoader = ({ rowCount, columnCount }) => {
  return (
    <TableContainer component={Paper} className='mb-4 mt-4'>
      <Table>
        <TableHead>
          <TableRow>
            {Array.from({ length: columnCount }).map((_, index) => (
              <TableCell key={index}>
                <Skeleton variant="text" />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: rowCount }).map((_, rowIndex) => (
            <TableRow key={rowIndex}>
              {Array.from({ length: columnCount }).map((_, colIndex) => (
                <TableCell key={colIndex}>
                  <Skeleton variant="text" />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

TableSkeletonLoader.defaultProps = {
    rowCount: 3,
    columnCount: 3
  }
  
  TableSkeletonLoader.PropTypes = {
    rowCount: PropTypes.number,
    columnCount: PropTypes.number
  }
  

export default TableSkeletonLoader;
